import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TokenGuard implements CanActivate {

  constructor(public route: Router) { }

  canActivate() {
    const token = sessionStorage.getItem('token');
    if (token) {
      return true;
    } else {
      this.redirectToLogin();
      return false;
    }
  }

  redirectToLogin() {
    this.route.navigateByUrl(`/login`);
  }

}
