import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root'
})
export class ChaveService {

  private base = environment.base;

  constructor(
  ) {
  }

  public getTokenMd5(){
    const md5 = new Md5();
    return md5.appendStr(this.base).end();
  }
}
