import { ChaveService } from './chave.service';
import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

class TokenService {
}

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {

  constructor(
    private chaveService: ChaveService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const TOKEN = sessionStorage.getItem('token');
    const CHAVE = this.chaveService.getTokenMd5();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${TOKEN}`,
      'Accept': '*/*',
      'Chave': `${CHAVE}`,
      'Content-Type': 'application/json'
    });

    const cloneReq = req.clone({headers});

    return next.handle(cloneReq);
  }
}
