import { TokenGuard } from './../shared/guards/token.guard';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'ultima-batida',
    loadChildren: () => import('./ultima-batida/ultima-batida.module').then((m) => m.UltimaBatidaPageModule),
    canActivate: [TokenGuard]
  },
  {
    path: 'batidas',
    loadChildren: () => import('./batidas/batidas.module').then(m => m.BatidasPageModule),
    canActivate: [TokenGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
