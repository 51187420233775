
// apiUrl: 'https://apirestponto.gtcontroller.com.br/api/v1' //dev
// apiUrl: 'https://apiponto.gtcontroller.com.br/api/v1' //prod
// cpf teste: 61046007351

export const environment = {
  production: true,
  apiUrl: 'https://pontoapi.gtcontroller.com.br/api',
  base: 'eusebio'
};
